import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/node_modules/react-responsive-modal/styles.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/node_modules/swiper/swiper.min.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/node_modules/swiper/modules/effect-fade/effect-fade.min.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/node_modules/swiper/modules/navigation/navigation.min.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/node_modules/bootstrap/dist/css/bootstrap.min.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/public/assets/css/main.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/public/assets/imgs/page/about-1.png");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/public/assets/imgs/page/about-5.jpg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/public/assets/imgs/page/graph-02.jpg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/public/assets/imgs/theme/icons/icon-whatsapp.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/public/assets/imgs/theme/icons/phone-call.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/app/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/Breadcrumb.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/Button.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/CustomCheckbox.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/CustomInput.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/CustomMultiSelect.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/CustomSelect.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/FileUpload.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/OffCanvas.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/Search.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/Slider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/StarRating.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/Table.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/TableBody.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/TableHeader.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/Tags.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/TextArea.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/core-components/ThumbSlider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Account/AccountLayout.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Account/AddressCard.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Account/AddressForm.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Account/OrderFilters.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Account/ProfileForm.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Account/UpdateDetails.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Banners/Banner.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Banners/BannerSlide.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Banners/BannerSlider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Banners/BannersList.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Banners/GiftHamper.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Cart/Cart.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Cart/CartItem.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Cart/CartOffCanvas.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Cart/CartSummary.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Cart/OrderItems.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Checkout/Checkout.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Checkout/OrderTable.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Checkout/PaymentMethod.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Checkout/UserAddressList.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Festive/Festive01.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/GlobalOffCanvas.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/Category/CategoryHeader.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/Category/CategoryItem.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/Category/CategoryListing.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/ExtraFiltersForm.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/HorizontalListing.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/ListingWithFilters.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/Product/DealsList.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/Product/ProductDetails.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/Product/ProductHamperDeal.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/Product/ProductItem.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/Product/ProductListing.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/Product/ProductReviews.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/Product/ReviewForm.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/Product/ReviewList.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Listing/Product/ShareModal.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Orders/OrderDetail.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Orders/OrderListing.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/QuickView.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/RazorPay.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/StaticPages.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Success.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Widget/Listing.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/group-components/Wishlist/Wishlist.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/Header.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/Login.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/MobileMenu.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/Pagination.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/Register.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/components/StructuredData.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2821754516/src/avarya-retail-ecommerce/src/utils/helper.ts");
